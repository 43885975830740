import { render, staticRenderFns } from "./NewThemeTable.vue?vue&type=template&id=9cb93dbc&scoped=true"
import script from "./NewThemeTable.vue?vue&type=script&lang=js"
export * from "./NewThemeTable.vue?vue&type=script&lang=js"
import style0 from "./NewThemeTable.vue?vue&type=style&index=0&id=9cb93dbc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cb93dbc",
  null
  
)

export default component.exports