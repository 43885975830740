<template>
  <div
    class="item data-iterator-card"
    :class="[
      checked ? 'data-iterator-card--selected' : '',
      item.status === 'active' ? 'data-iterator-card--green' : '',
    ]"
  >
    <v-card elevation="4">
      <div class="data-iterator-card__checkbox-container">
        <checkbox :checked="checkedComputed" @change="handleCheckbox" />
      </div>
      <v-img
        class="data-iterator-card__image"
        position="top center"
        v-if="item.img"
        :src="item.img"
        width="100%"
        height="200px"
        max-height="200px"
      >
        <template v-slot:placeholder>
          <image-loader />
        </template>
      </v-img>

      <div class="instance-actions px-12">
        <div class="instance-actions__content text-center">
          <v-btn
            v-if="item.status !== 'active'"
            color="primary"
            class="mb-4"
            elevation="0"
            small
            max-width="184px"
            min-width="184px"
            @click.native="$emit('action-button-activate', item)"
          >
            <span class="font-weight-bold white--text">{{ $t('button.theme.use') }}</span>
          </v-btn>
          <v-btn
            color="white"
            elevation="0"
            small
            max-width="184px"
            min-width="184px"
            target="_blank"
            :href="`https://wp-themes.com/${item.name}`"
          >
            <span class="font-weight-bold">{{ $t('button.preview') }}</span>
          </v-btn>
        </div>
      </div>
      <v-divider />
      <v-card-title class="d-flex justify-space-between align-center">
        <h5 v-html="item.title"></h5>

        <div class="d-flex align-center">
          <v-tooltip
            v-if="item.update !== 'available'"
            open-delay="150"
            bottom
            transition="custom-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                :color="item.status === 'active' ? 'primary' : 'gray darken-1'"
                size="16"
              >
                $alertok
              </v-icon>
            </template>
            {{ $t('tooltip.instance.theme.update.current') }}
          </v-tooltip>

          <v-tooltip v-else open-delay="150" bottom transition="custom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" color="error" size="16"
                >$alertwarning</v-icon
              >
            </template>
            {{ $t('tooltip.instance.theme.update.available') }}
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-text class="p-3">
        <span v-html="getDescription(item.description)" class="base--text">
        </span>
        <a
          v-if="hasReadMoreButton"
          @click="$emit('action-button-read-more')"
          class="truncate-text"
        >
          <span class="base--text font-weight-regular"> ... </span>
          {{ $t('button.readMore') }}
        </a>
      </v-card-text>
      <v-card-actions class="pt-2 px-4">
        <div
          v-if="item.hasInstallInProgress()"
          class="d-flex w-100 justify-space-between"
        >
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <base-icon-button
                  v-on="on"
                  v-bind="attrs"
                  icon="$update"
                  :text="$t('button.installing')"
                  loading
                />
              </div>
            </template>

            <span class="base--text">{{ $t('message.status.install.inProgress') }}</span>
          </v-tooltip>
        </div>
        <div
          v-else-if="item.hasUpdateInProgress()"
          class="d-flex w-100 justify-space-between"
        >
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <base-icon-button
                  v-on="on"
                  v-bind="attrs"
                  icon="$update"
                  :text="$t('button.updating')"
                  loading
                />
              </div>
            </template>
            <span class="base--text">{{ $t('message.tatus.update.inProgress') }} </span>
          </v-tooltip>
        </div>
        <div v-else class="w-100 d-flex justify-space-between">
          <div class="d-flex">
            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <base-icon-button
                    v-on="on"
                    v-bind="attrs"
                    :disabled="item.status === 'active'"
                    :icon="
                      item.status === 'active' ? '$xcircle' : '$checkcircle'
                    "
                    :text="item.status === 'active' ? $t('message.status.activated') : $t('message.status.active')"
                    @click.native="$emit('action-button-activate', item)"
                  />
                </div>
              </template>
              <span class="base--text">
                <span v-if="item.status !== 'active'">{{ $t('tooltip.instance.theme.activated.notActive') }}</span>
                <span v-else> {{ $t('tooltip.instance.theme.activated.active') }}</span>
           </span
              >
            </v-tooltip>
            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <base-icon-button
                    v-on="on"
                    v-bind="attrs"
                    icon="$eye"
                    :text="$t('button.preview')"
                    target="_blank"
                    :href="`https://wp-themes.com/${item.name}`"
                  />
                </div>
              </template>
              <span class="base--text">{{ $t('tooltip.instance.theme.preview') }}</span>
            </v-tooltip>
            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
              v-if="item.update === 'available'"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <base-icon-button
                    v-on="on"
                    v-bind="attrs"
                    color="error"
                    icon="$update"
                    :text="$t('button.update')"
                    @click.native="$emit('action-button-update', item)"
                  />
                </div>
              </template>

              <span class="base--text">{{ $t('tooltip.instance.theme.update') }}</span>
            </v-tooltip>
          </div>

          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <base-icon-button
                  icon="$thrash"
                  :text="$t('button.remove')"
                  @click.native="$emit('action-button-delete', item)"
                />
              </div>
            </template>

            <span class="base--text">{{ $t('tooltip.instance.theme.delete') }}</span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import DataIteratorItemMixin from "../../mixins/DataIteratorItemMixin";

import BaseIconButton from "../buttons/BaseIconButton.vue";
import Checkbox from "../buttons/Checkbox.vue";
import ImageLoader from "../ImageLoader.vue";

export default {
  mixins: [DataIteratorItemMixin],
  props: {
    item: Object,
    checked: Boolean,
  },
  components: {
    ImageLoader,
    BaseIconButton,
    Checkbox,
  },
  data() {
    return {
      hasReadMoreButton: true,
    };
  },
  methods: {
    getThemeUrl: function (item) {
      return item.toLowerCase().replaceAll(" ", "").replaceAll("-", "");
    },
    getDescription(item) {
      let currentLength = 100;

      if (item.length <= currentLength) {
        this.hasReadMoreButton = false;
        return item;
      }

      for (let i = currentLength; i > 0; i--) {
        if (item.charAt(i - 1) === " ") {
          let result = item.slice(0, i - 1);
          return result + " ";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.data-iterator-card {
  &__image {
    border-radius: 0;
  }
  &:hover {
    .instance-actions {
      opacity: 1;
      &__content {
        transform: translateY(0px);
      }
    }
  }
  .instance-actions {
    position: absolute;
    top: 0;
    height: 200px;
    z-index: 5;
    width: 100%;
    transition: opacity 0.2s ease;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }
  .v-card {
    border: 0px solid;
    border-top: 6px solid;
    border-color: var(--v-gray-lighten3);
    border-radius: 8px;

    .v-card__text {
      position: relative;
    }

    .v-card__title {
      padding-top: 18px;
    }
  }

  .data-iterator-card__checkbox-container {
    background: white;
    left: -34px;
    top: 210px;
    width: 42px !important;
    height: 42px !important;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: opacity 0.24s ease;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12),
      0px 8px 24px 6px rgba(0, 0, 0, 0.02), 0px 8px 32px rgba(0, 0, 0, 0.08);
    &::before {
      background: white;
      width: 24px;
      height: 72px;
      top: -6px;
      right: -17px;
    }

    .checkbox {
      margin: 0px !important;
    }
  }

  &:hover {
    .data-iterator-card__checkbox-container {
    }
  }

  &.data-iterator-card--selected {
    .v-card {
      &:before {
        box-shadow: none !important;
      }
    }
  }

  &--green {
    .v-card {
      border-top-color: var(--v-primary-base);
    }
  }

  &--red {
    .v-card {
      border-top-color: var(--v-error-base);
    }

    .data-iterator-card__checkbox-container {
      border-color: var(--v-error-base);
    }
  }
}

.truncate {
  margin-bottom: auto;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis " <span class=" gray--text text--darken-2
    font-weight-regular " >... </span>Read More";
  padding-bottom: 0px;
}

.truncate + a {
  vertical-align: top;
  margin-top: -1px;
  font-weight: $font-weight-bold;
  color: var(--v-info-base);
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  right: 24px;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(255, 255, 255, 1) 40%
  );
  height: 20px;
  width: 128px;
}

.truncate-text {
  font-weight: $font-weight-bold;
  color: var(--v-info-base);
  display: inline;
  justify-content: flex-end;
  margin-left: -4px;
}
.v-application--is-rtl{
    .data-iterator-card{
        .data-iterator-card__checkbox-container{
            right: -32px;
            &::before{
                right: 32px;
            }
        }
    }
}
</style>
