<template>
  <tbody class="item">
    <tr v-for="item in rowsNumber" :key="item">
      <td></td>

      <td>
        <div class="d-flex align-center">
          <v-skeleton-loader type="avatar" class="mr-3" />
          <div class="d-flex flex-column" style="width: 400px">
            <v-skeleton-loader type="text " style="width: 50%" />
            <v-skeleton-loader type="text" />
          </div>
        </div>
      </td>

      <td v-for="itemCell in 2" :key="item + itemCell" class="pl-0">
        <v-skeleton-loader type="text" />
      </td>
      <td style="width: 40px">
        <div class="d-flex align-center">
          <v-skeleton-loader class="mr-3" type="button" />
          <v-skeleton-loader type="button" />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    rowsNumber: {
      type: Number,
      default: 8,
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  padding: 0px 8px;
  position: relative;
  &:after {
    content: "";
    border-bottom: 1px solid #E5E5F0;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
  }
}
tr td {
  border-bottom: transparent;

  &:first-child {
    display: none;
  }
}

.v-skeleton-loader::v-deep {
  .v-skeleton-loader__button {
    width: 36px;
    height: 36px;
  }
  .v-skeleton-loader__avatar {
    width: 64px;
    height: 64px;
    max-width: 64px;
  }
}
</style>
