import Api from "../apis/Api"
import ActionModalMixin from "./ActionModalMixin"

const ThemesActionsMixin = {
    mixins: [ActionModalMixin],
    methods: {
        // INSTALL ACTION
        showInstallModal(item) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.advanced.theme.modal.install.title');
            this.modalOptions.message = this.$t('heading.instance.advanced.theme.modal.install.info', {theme: item.description});
            this.modalOptions.item = item

            this.modalOptions.buttons.unshift({
                label: this.$t('button.install'),
                color: "primary",
                onclick: () => {
                    this.installTheme(item);
                },
            });
            this.modalOptions.item = item;
            this.modalOptions.open = true;
        },
        installTheme(item) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";
            Api.post(
                `/instances/${this.applicationId}/wordpress/themes/new/${item.slug}/install`
            )
                .then(() => {
                    item.async_status = { install: "pending" };
                    this.$root.$emit("theme-item-changed", { ...item });

                    this.modalOptions.submittingSuccess = this.$t('notification.instance.theme.install.success')
                    this.reloadThemes();

                    this.$store.dispatch("addAlert", {
                        success: true,
                        successMessage: this.modalOptions.submittingSuccess,
                    });

                    // this.addThemes = false;
                })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },

        // ACTIVATE ACTION
        showActivateModal(item) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.advanced.theme.modal.activate.title', {count: 1});
            this.modalOptions.message = this.$t('heading.instance.advanced.theme.modal.activate.info', {count: 1});
            this.modalOptions.icon = "$activate";

            this.modalOptions.item = item;

            this.modalOptions.buttons.unshift({
                label: this.$t('button.activate'),
                color: "primary",
                onclick: () => {
                    this.activateTheme(item);
                },
            });
            this.modalOptions.open = true;
        },
        showMassActivateModal(items) {
            this.resetModal();

            this.modalOptions.title = this.$t('heading.instance.advanced.theme.modal.activate.title', {count: items.length});
            this.modalOptions.message = this.$t('heading.instance.advanced.theme.modal.activate.info', {count: items.length});
            this.modalOptions.icon = "$activate";

            this.modalOptions.item = items

            this.modalOptions.buttons.unshift({
                label: this.$t('button.activate'),
                color: "primary",
                onclick: () => {
                    this.massActivateTheme(this.checkedItems);
                },
            });
            this.modalOptions.open = true;
        },
        activateTheme(item) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            Api.put(
                `/instances/${this.applicationId}/wordpress/themes/${item.name}/activate`
            )
                .then(() => {
                    this.modalOptions.submittingSuccess = this.$t('notification.instance.theme.activate.success', {count: 1})
                    this.reloadThemes();

                    this.$store.dispatch("addAlert", {
                        success: true,
                        successMessage: this.modalOptions.submittingSuccess,
                    });
                })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },
        massActivateTheme(items) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            // const affectedItems = items.map(checkedItem => {
            //     this.items.find(item => (item.name == checkedItem))
            // })

            const activateAll = items.map(item => {
                return Api.put(`/instances/${this.applicationId}/wordpress/themes/${item}/activate`)
            })

            Promise.all(activateAll).then(() => {
                this.modalOptions.submittingSuccess = this.$t('notification.instance.theme.activate.success', {count: items.length})
                this.reloadThemes();

                this.$store.dispatch("addAlert", {
                    success: true,
                    successMessage: this.modalOptions.submittingSuccess,
                });
            })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },

        // UPDATE ACTION
        showUpdateModal(item) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.advanced.theme.modal.update.title', {count: 1});
            this.modalOptions.icon = "$update";
            this.modalOptions.message = this.$t('heading.instance.advanced.theme.modal.update.info.one', {version: item.update_version});
            this.modalOptions.item = item;

            this.modalOptions.buttons.unshift({
                label: this.$t('button.update'),
                color: "primary",
                onclick: () => {
                    this.updateTheme(item);
                },
            });
            this.modalOptions.open = true;
        },
        showMassUpdateModal(items) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.advanced.theme.modal.update.title', {count: items.length});
            this.modalOptions.icon = "$update";

            let message = "";

            this.modalOptions.item = items;

            this.checkedItems.map((item) => {
                const foundItem = this.allItems.find((i) => i.name === item);
                this.modalOptions.message = ``;

                if (!foundItem.update_version) {
                    return "";
                }
                message =
                    message +
                    `<li>${this.$t('heading.instance.advanced.theme.modal.update.info.other.content', {
                        theme: `<b class="capitalize">${foundItem.name}</b>`,
                        version: `<b>${foundItem.version}</b>`,
                        updateVersion: `<b>${foundItem.update_version}</b>`
                    })}</li>`;

            });

            this.modalOptions.message =
            this.$t('heading.instance.advanced.theme.modal.update.info.other.title') +
            `<ul style="${message ? "margin-top: 24px" : "display: none"
                }">${message}</ul>`;

            this.modalOptions.buttons.unshift({
                label: this.$t('button.update'),
                color: "primary",
                onclick: () => {
                    this.massUpdateTheme(this.checkedItems);
                },
            });
            this.modalOptions.open = true;
        },
        updateTheme(item) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";
            Api.put(`/instances/${this.applicationId}/wordpress/themes/${item.name}/update`)
                .then(() => {
                    item.async_status = { update: "pending" };
                    this.$root.$emit("theme-item-changed", item);

                    this.modalOptions.submittingSuccess = this.$t('notification.instance.theme.update.success', {count: 1})
                    this.reloadThemes();

                    this.$store.dispatch("addAlert", {
                        success: true,
                        successMessage: this.modalOptions.submittingSuccess,
                    });
                })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },
        massUpdateTheme(items) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            this.modalOptions.item = items;

            const updateAll = items.map(item => {
                return Api.put(`/instances/${this.applicationId}/wordpress/themes/${item}/update`)
            })
            Promise.all(updateAll).then(() => {
                this.modalOptions.submittingSuccess = this.$t('notification.instance.theme.update.success', {count: items.length})
                this.reloadThemes();

                this.$store.dispatch("addAlert", {
                    success: true,
                    successMessage: this.modalOptions.submittingSuccess,
                });
            })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },

        // DELETE ACTION

        showDeleteModal(item) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.advanced.theme.modal.delete.title', {count: 1});
            this.modalOptions.message = this.$t('heading.instance.advanced.theme.modal.delete.info', {count: 1});
            this.modalOptions.icon = "$alertwarning";
            this.modalOptions.color = "error";

            this.modalOptions.item = item

            this.modalOptions.formFields = [
                {
                    message: `<b>${this.$t('message.confirmAction')}</b>`,
                    label: this.$t('form.confirmDelete.theme', {count: 1}),
                    name: "confirm",
                    type: "checkbox",
                    required: true,
                },
            ];

            this.modalOptions.buttons.unshift({
                label: this.$t('button.delete'),
                color: "error",
                onclick: () => {
                    this.deleteTheme(item);
                },
            });
            this.modalOptions.open = true;
        },
        showMassDeleteModal(items) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.advanced.theme.modal.delete.title', {count: items.length});
            this.modalOptions.message = this.$t('heading.instance.advanced.theme.modal.delete.info', {count: items.length});
            this.modalOptions.icon = "$alertwarning";
            this.modalOptions.color = "error";

            this.modalOptions.item = items

            this.modalOptions.formFields = [
                {
                    message: `<b>${this.$t('message.confirmAction')}</b>`,
                    label: this.$t('form.confirmDelete.theme', {count: items.length}),
                    name: "confirm",
                    type: "checkbox",
                    required: true,
                },
            ];

            this.modalOptions.buttons.unshift({
                label: this.$t('button.delete'),
                color: "error",
                onclick: () => {
                    this.massDeleteTheme(this.checkedItems);
                },
            });
            this.modalOptions.open = true;
        },
        deleteTheme(item) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            Api.delete(`/instances/${this.applicationId}/wordpress/themes/${item.name}`)
                .then(() => {
                    this.modalOptions.submittingSuccess = this.$t('notification.instance.theme.delete.success', {count: 1})
                    this.reloadThemes();

                    this.$store.dispatch("addAlert", {
                        success: true,
                        successMessage: this.modalOptions.submittingSuccess,
                    });
                })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },
        massDeleteTheme(items) {
            let activeTheme = this.items.find((i) => {
                return i.status == 'active';
            });

            const deleteAll = items.flatMap(item => {
                if (item == activeTheme.name && !this.$store.state.demoMode) {
                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.$t('notification.instance.theme.delete.error')
                    });
                    return [];
                }
                return Api.delete(`/instances/${this.applicationId}/wordpress/themes/${item}`);
            })

            if (!deleteAll.length) {
                this.modalOptions.open = false;
                this.modalOptions.persistent = false;
                return;
            }

            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            Promise.all(deleteAll).then(() => {
                this.modalOptions.submittingSuccess = this.$t('notification.instance.theme.delete.success', {count: deleteAll.length})
                this.reloadThemes();

                this.$store.dispatch("addAlert", {
                    success: true,
                    successMessage: this.modalOptions.submittingSuccess,
                });
                this.checkedItems = []
            })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },
    }
}

export default ThemesActionsMixin