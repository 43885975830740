var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-iterator-card",class:_vm.item.featured ? 'data-iterator-card--featured' : ''},[_c('v-card',{staticClass:"item",attrs:{"elevation":"4"}},[(_vm.item.featured)?_c('div',[_c('div',{staticClass:"featured-label"},[_c('span',{staticClass:"featured-label__text"},[_vm._v(_vm._s(_vm.$t('general.featured')))])])]):_vm._e(),_c('v-img',{staticClass:"data-iterator-card__image",attrs:{"src":_vm.screenshotUrl,"width":"100%","height":"200px","max-height":"200px"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('image-loader')]},proxy:true}])}),_c('v-divider'),_c('div',{staticClass:"instance-actions px-12"},[_c('div',{staticClass:"instance-actions__content text-center"},[(_vm.item.status !== 'active')?_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","elevation":"0","small":"","max-width":"184px","min-width":"184px"},on:{"click":function($event){return _vm.$emit('action-button-install', _vm.item)}}},[_c('span',{staticClass:"font-weight-bold white--text"},[(
                !_vm.item.hasInstallInProgress() && !_vm.item.hasUpdateInProgress()
              )?[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16px","color":"white"}},[_vm._v("$installIcon")]),_vm._v(" "+_vm._s(_vm.$t('button.theme.install'))+" ")]:_c('loader',{staticClass:"mr-0",attrs:{"color":"white","size":"24","noMargins":true}})],2)]):_vm._e(),_c('v-btn',{attrs:{"color":"white","elevation":"0","small":"","max-width":"184px","min-width":"184px","target":"_blank","href":`https://wp-themes.com/${_vm.item.slug}`}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('button.preview')))])])],1)]),_c('v-card-title',{staticClass:"d-flex justify-space-between align-center"},[_c('h5',{domProps:{"innerHTML":_vm._s(_vm.item.name)}})]),_c('v-card-text',{staticClass:"p-3"},[_c('span',{staticClass:"base--text",domProps:{"innerHTML":_vm._s(_vm.getDescription(_vm.item.description))}}),(_vm.hasReadMoreButton)?_c('a',{staticClass:"truncate-text",on:{"click":function($event){return _vm.$emit('action-button-read-more')}}},[_c('span',{staticClass:"base--text font-weight-regular"},[_vm._v(" ... ")]),_vm._v(" "+_vm._s(_vm.$t('button.readMore'))+" ")]):_vm._e()]),_c('v-card-actions',{},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center mr-3"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","percent":_vm.item.rating / 100}},[_vm._v(" $starIcon ")]),_c('b',{staticClass:"p-4"},[_vm._v(_vm._s(Math.ceil((_vm.item.rating / 10) * 5) / 10))])],1)]),_c('div',{staticClass:"d-flex align-center ml-auto"},[(_vm.item.preview_url)?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"min-width":"38px","max-width":"38px"},attrs:{"outlined":"","small":"","color":"gray","elevation":"0","target":"_blank","href":_vm.item.type
                  ? _vm.item.preview_url
                  : `https://wp-themes.com/${_vm.item.slug}`}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-0",attrs:{"size":"18","color":"gray darken-2"}},[_vm._v(" $eye ")])],1)]}}],null,false,3951817382)},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('tooltip.instance.theme.preview')))])]):_vm._e(),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",staticStyle:{"min-width":"38px","max-width":"38px"},attrs:{"color":"primary","small":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('action-button-install', _vm.item)}}},'v-btn',attrs,false),on),[(
                  !_vm.item.hasInstallInProgress() && !_vm.item.hasUpdateInProgress()
                )?_c('v-icon',{staticClass:"mr-0",attrs:{"size":"18","color":"white"}},[_vm._v(" $installIcon ")]):_c('loader',{staticClass:"mr-0",attrs:{"color":"white","size":"24","noMargins":true}})],1)]}}])},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('tooltip.instance.theme.install')))])])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }