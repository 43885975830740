<template>
  <div
    class="data-iterator-card"
    :class="
      item.featured ? 'data-iterator-card--featured' : ''
    "
  >
    <v-card elevation="4" class="item">
      <div v-if="item.featured">
        <div class="featured-label">
          <span class="featured-label__text">{{ $t('general.featured') }}</span>
        </div>
      </div>
      <v-img
        class="data-iterator-card__image"
        :src="screenshotUrl"
        width="100%"
        height="200px"
        max-height="200px"
      >
        <template v-slot:placeholder>
          <image-loader />
        </template>
      </v-img>
      <v-divider />

      <div class="instance-actions px-12">
        <div class="instance-actions__content text-center">
          <v-btn
            v-if="item.status !== 'active'"
            color="primary"
            class="mb-4"
            elevation="0"
            small
            max-width="184px"
            min-width="184px"
            @click="$emit('action-button-install', item)"
          >
            <span class="font-weight-bold white--text">
              <template
                v-if="
                  !item.hasInstallInProgress() && !item.hasUpdateInProgress()
                "
              >
                <v-icon size="16px" color="white" class="mr-1"
                  >$installIcon</v-icon
                >
                {{ $t('button.theme.install') }}
              </template>

              <loader
                v-else
                color="white"
                size="24"
                class="mr-0"
                :noMargins="true"
              />
            </span>
          </v-btn>
          <v-btn
            color="white"
            elevation="0"
            small
            max-width="184px"
            min-width="184px"
            target="_blank"
            :href="`https://wp-themes.com/${item.slug}`"
          >
            <span class="font-weight-bold">{{ $t('button.preview') }}</span>
          </v-btn>
        </div>
      </div>

      <v-card-title class="d-flex justify-space-between align-center">
        <h5 v-html="item.name"></h5>
      </v-card-title>

      <v-card-text class="p-3">
        <span v-html="getDescription(item.description)" class="base--text">
        </span>
        <a
          v-if="hasReadMoreButton"
          @click="$emit('action-button-read-more')"
          class="truncate-text"
        >
          <span class="base--text font-weight-regular"> ... </span>
          {{ $t('button.readMore') }}
        </a>
      </v-card-text>

      <v-card-actions class="">
        <div class="d-flex align-center">
          <div class="d-flex align-center mr-3">
            <v-icon size="20" class="mr-1" :percent="item.rating / 100">
              $starIcon
            </v-icon>
            <b class="p-4">{{ Math.ceil((item.rating / 10) * 5) / 10 }}</b>
          </div>
          <!-- 
            <div class="d-flex">
              <v-icon size="20" class="mr-1" color="gray lighten-1">
                $installIcon
              </v-icon>
              <b class=""> </b>
            </div> -->
        </div>
        <div class="d-flex align-center ml-auto">
          <v-tooltip
            v-if="item.preview_url"
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                style="min-width: 38px; max-width: 38px"
                outlined
                small
                color="gray"
                elevation="0"
                target="_blank"
                :href="
                  item.type
                    ? item.preview_url
                    : `https://wp-themes.com/${item.slug}`
                "
              >
                <v-icon size="18" class="mr-0" color="gray darken-2">
                  $eye
                </v-icon>
              </v-btn>
            </template>

            <span class="base--text">{{ $t('tooltip.instance.theme.preview') }}</span>
          </v-tooltip>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                style="min-width: 38px; max-width: 38px"
                class="ml-3"
                color="primary"
                @click="$emit('action-button-install', item)"
                small
                elevation="0"
              >
                <v-icon
                  v-if="
                    !item.hasInstallInProgress() && !item.hasUpdateInProgress()
                  "
                  size="18"
                  class="mr-0"
                  color="white"
                >
                  $installIcon
                </v-icon>

                <loader
                  v-else
                  color="white"
                  size="24"
                  class="mr-0"
                  :noMargins="true"
                />
              </v-btn>
            </template>
            <span class="base--text">{{ $t('tooltip.instance.theme.install') }}</span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import DataIteratorItemMixin from "../../mixins/DataIteratorItemMixin";
import ImageLoader from "../ImageLoader.vue";
import Api from "@/apis/Api";

export default {
  components: {
    ImageLoader,
  },
  mixins: [DataIteratorItemMixin],
  props: {
    item: Object,
  },
  data() {
    return {
      hasReadMoreButton: true,
    };
  },
  methods: {
    getPluginUrl: function (item) {
      return item.toLowerCase().replaceAll(" ", "").replaceAll("-", "");
    },
    getDescription(item) {
      let currentLength = 100;

      if (item.length <= currentLength) {
        this.hasReadMoreButton = false;
        return item;
      }

      for (let i = currentLength; i > 0; i--) {
        if (item.charAt(i - 1) === " ") {
          let result = item.slice(0, i - 1);
          return result + " ";
        }
      }
    },
  },
  computed: {
    screenshotUrl() {
      if (this.item.type && this.item.type === 'custom') {
        let apiUrl = Api.defaults.baseURL;
        return `${apiUrl}/instances/onboarding/theme/${this.item.id}/screenshot`;
      }
      return this.item.screenshot_url;
    }
  }
};
</script>

<style lang="scss" scoped>
.truncate-text {
  font-weight: $font-weight-bold;
  color: var(--v-info-base);
  display: inline;
  justify-content: flex-end;
  margin-left: -4px;
}
.v-card {
  border: 0px solid;
  border-top: 6px solid;
  border-color: var(--v-gray-lighten3);
  border-radius: 8px;
}
.data-iterator-card {
  border-top: none !important;
  .item {
    border-top: none !important;
  }

  .instance-actions {
    position: absolute;
    top: 0;
    height: 200px;
    z-index: 5;
    width: 100%;
    transition: opacity 0.2s ease;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }
  &:hover {
    .instance-actions {
      opacity: 1;
      &__content {
        transform: translateY(0px);
      }
    }
  }
}

.data-iterator-card--featured {
  .v-card {
    border-color: #fadd77;
    .primary-on-hover:hover {
      color: var(--v-primary-base) !important;
      transition: color 0.24s ease;

      .v-icon svg,
      svg {
        color: var(--v-primary-base) !important;
        caret-color: var(--v-primary-base) !important;
      }
    }
  }
  .featured-label {
    position: absolute;
    top: -14px;
    right: -8px;
    overflow: hidden;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background: #f2ce98;
      z-index: 0;
    }
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
    }
    .featured-label__text {
      transform: rotate(45deg);
      color: #ec7b13;
      display: block;
      position: relative;
      font-size: $font-size-xxs;
      line-height: $line-height-xxs;
      font-weight: $font-weight-bold;
      bottom: 8px;
      left: 8px;
      text-align: center;
      z-index: 10;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -24px;
        width: 120px;
        height: 18px;
        background: #fff2d3;
        z-index: -1;
      }
    }
  }
}
</style>
